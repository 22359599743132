<template>
  <div>
    <table :id="id" class="display table table-condensed" style="width:100% !important; border-collapse:collapse;">
      <thead>
        <tr>
          <!--<th width="2%"></th>-->
          <!--<th></th>-->
          <th>location</th>
          <th width="12%"> Vessel</th>
          <th width="20%">Departure date</th>
          <th width="20%">Charge code</th>
          <th width="20%">Badge</th>
          <th width="19%">from</th>
          <th width="19%">to</th>
          <th width="15%">mode</th>
          <th width="15%">status</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(movement, index) in movements">
          <tr>
            <!--<td>{{ index + 1 }}</td>-->
            <!--<td></td>-->
            <td>{{ getLocationName(movement.LocationId) }}</td>
            <td>{{ movement.Vessel.Name }}</td>
            <td>{{ movement.chargeCode }}</td>
            <td>
              <span v-if="movement.barge && movement.barge.name">{{ movement.barge.name }}</span>
              <span v-else>___</span>
            </td>
            <td>{{ movement.DepartureDate | toDateAtTime }}</td>
            <td>{{ getLocationName(movement.FromLocationId)  }}</td>
            <td>{{ getLocationName(movement.ToLocationId) }}</td>
            <td>{{ movement.Mode | toMovementModeDescription }}</td>
            <td>{{ movement.Status | toMovementStatusDescription }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss">
  .action-btn {
    cursor: pointer;
    max-width: 40% !important;
  }

  /*table tbody tr:nth-child(odd) td {
    background-color: #ffffff !important;
  }*/

  tr.group {
    background-color: #f3f4f8 !important;
  }

</style>

<script>
  import { mapState } from 'vuex'

  import Enums from '@/utils/enums'

  import { toDateAtTime } from '@/filters'
  import { toMovementStatusDescription, toMovementModeDescription } from '@/filters/enum'


  export default {
    name: 'movement-list',
    components: {
    },
    props: ['id', 'movements', 'tableProps', 'emptyTableMessage'],
    data: () => ({
      Enums: Enums,
      tableObject: null,
      toMovementStatusDescription
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        locations: state => state.locationModule.locations,
      }),
    },
    filters: {
      toMovementStatusDescription,
      toMovementModeDescription,
      toDateAtTime
    },
    methods: {
      refresh() {
        const self = this;
        if (self.tableObject) {
          self.tableObject.destroy();
          $(`#${self.id}`).empty()
        }
        var groupColumn = 0;
        self.tableObject = $(`#${self.id}`).DataTable({
          language: {
            emptyTable: self.emptyTableMessage
          },
          searching: true,
          search: {
            regex: false,
            smart: false
          },
          paging: true,
          info: true,
          columnDefs: [{ visible: false, targets: groupColumn }],
          //order: (self.tableProps && self.tableProps.order ? self.tableProps.order : []),
          //pageLength: (self.tableProps && self.tableProps.page ? self.tableProps.page.length : 10),
          //displayStart: (self.tableProps && self.tableProps.page ? self.tableProps.page.length * self.tableProps.page.page : 0),
          //search: (self.tableProps && self.tableProps.search ? self.tableProps.search : null),
          dom: 'lBfrtip',
          buttons: [
            'csv', 'excel'
          ],
          drawCallback: function (settings) {
            var api = this.api();
            var rows = api.rows({ page: 'current' }).nodes();
            var last = null;

            api
              .column(groupColumn, { page: 'current' })
              .data()
              .each(function (group, i) {
                if (last !== group) {
                  const text = group
                  $(rows)
                    .eq(i)
                    .before('<tr class="group"><td colspan="6">' + text + '</td></tr>');
                  last = group;
                }
              });
          },
        });


        if (self.tableProps.order && self.tableProps.order.length) {
          self.tableObject.order(self.tableProps.order).draw()
        }

        if (self.tableProps.search) {
          self.tableObject.search(self.tableProps.search).draw()
        }

        $(`#${self.id}`).on('order.dt', function (e, settings) {
          if (self.tableObject.order()) {
            self.tableProps.order = self.tableObject.order()
          }
        });

        $(`#${self.id}`).on('search.dt', function () {
          self.tableProps.search = self.tableObject.search();
        });
      },
      getLocationName(locationId) {
        if (locationId) {
          return this.locations.filter(location => location.id == locationId)[0].name
        }
        return ''
      }
    },
    mounted() {
    //  this.refresh()
    },
    beforeDestroy() {
      if (this.tableObject) {
        this.$nextTick(() => {
          this.tableObject.destroy();
        });
      }
    }
  }
</script>

<template>
  <div id="monthEndGenerationView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>generate month end data</h4>
        <app-modal :show="showMonthEndWarning" :static="true" size="medium" title="Run month end?">
          <fieldset>
            <p class="font-weight-bold">
              Are you sure you want to run month end for {{ params.monthAndYear }}?
            </p>
            <p class="font-weight-bold text-danger">
              This action is not reversible and you will not be able to run month end for this same period again
            </p>
          </fieldset>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click="showMonthEndWarning = false">No</button>
            <button class="btn btn-primary d-inline mr-2" @click="showMonthEndWarning = false; generateMonthEndData()">Yes</button>
          </template>
        </app-modal>
        <div class="form-group row mt-4 d-flex justify-content-end clearfix mb-4">
          <!--<select class="form-control col-md-4" v-model="location" required disabled>
            <option value="" disabled>-- Select district --</option>
            <option v-for="location in terminalLocations" :value="location">{{ location.name }}</option>
          </select>-->
        </div>
        <div class="d-flex justify-content-end clearfix mb-4">
          <input type="month" class="form-control col-md-4" v-model="params.monthAndYear" :max="maxMonth" />
          <button class="btn btn-primary" @click="generateMonthEndData();">Generate</button>
        </div>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
      </div>
    </div>
    <div class="row" v-if="movements.length || failedMovements.length">
      <div class="col">
        <MonthlyMovementList v-if="movements.length" ref="monthlyMovementList" :tableProps="monthlyMovementListProps" :id="'monthlyMovementList'+ currentMonthEndTableKeyIndex" :key="'MonthlyMovementList-'+ currentMonthEndTableKeyIndex" :movements="movements" />
        <FailedMonthEndList v-if="failedMovements.length" ref="failedMovementList" :tableProps="failedMovementListProps" :id="'FailedMonthEndList'+ currentFailedMovementTableKeyIndex" :key="'FailedMonthEndList-'+ currentFailedMovementTableKeyIndex" :movements="failedMovements" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  input:invalid {
    border: 1px solid red !important;
  }
</style>

<script>
  import store from '@/store'
  import { mapState, mapGetters } from 'vuex'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'
  import { ALERT_TYPE_ERROR, CLOSE_MODAL_EVENT } from '@/utils/constants'
  import { FETCH_VESSELS, FETCH_LOCATIONS } from '@/store/action-type'
  import { monthEndService } from '@/services'

  import { AppError } from '@/utils/errors'

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import MonthlyMovementList from '@/components/movement/MonthlyMovementList'
  import FailedMonthEndList from '@/components/movement/FailedMonthEndList'


  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  export default {
    name: 'MonthEndGenerationView',
    components: {
      AppAlert,
      AppModal,
      MonthlyMovementList,
      FailedMonthEndList
    },
    mixins: [AlertMixin, ErrorMixin],
    data: () => ({
      //location: {},
      params: {
        monthAndYear: `${new Date().getFullYear()}-${('0' + (new Date().getMonth())).slice(-2)}`
      },
      showMonthEndWarning: false,
      movements: [],
      failedMovements: [],
      monthlyMovementListProps: {
        order: null,
        page: null,
        search: null,
        layout: 'list'
      },
      failedMovementListProps: {
        order: null,
        page: null,
        search: null,
        layout: 'list'
      },
      currentMonthEndTableKeyIndex: 1,
      currentFailedMovementTableKeyIndex: 1,
      errorAlertType: ALERT_TYPE_ERROR
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        locations: state => state.locationModule.locations
      }),
      ...mapGetters([
        'terminalLocations'
      ]),
      //maxtMonth() {
      //  return `${new Date().getFullYear()}-${('0' + (new Date().getMonth())).slice(-2)}`
      //},

      maxMonth() {
        const currentDate = new Date();
        const nextMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        const maxMonth = `${nextMonthDate.getFullYear()}-${('0' + (nextMonthDate.getMonth() + 1)).slice(-2)}`;
        return maxMonth;
      }
    },
    methods: {
      generateMonthEndData() {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, `running month end for ${self.params.monthAndYear}`)
        self.closeMessage()
        self.movements.splice(0, self.movements.length)
        self.failedMovements.splice(0, self.failedMovements.length)
        monthEndService.run(self.params.monthAndYear).then((result) => {
          self.showSuccessMessage('month end run completed successfully')
          self.movements = [...result.data.items]
          self.currentMonthEndTableKeyIndex += 1
          self.$nextTick(() => {
            self.$refs.monthlyMovementList.refresh();
          });
        }).catch((error) => {
          self.handleError(error)
          if (error instanceof AppError) {
            if (error.additionalData && error.additionalData.length) {
              self.failedMovements = [...error.additionalData]
              self.currentFailedMovementTableKeyIndex += 1
              self.$nextTick(() => {
                self.$refs.failedMovementList.refresh();
              });
            }
          }
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      }
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, 'loading data')
      const tasks = []
      tasks.push(store.dispatch(FETCH_VESSELS))
      tasks.push(store.dispatch(FETCH_LOCATIONS))
      Promise.all(tasks).then(() => {
        next()
      }).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
        })
      }).finally(() => {
        eventBus.$emit(Events.LongOperationCompleted)
      })
    },
    mounted() {
      const self = this
      //let userLocation = self.locations.filter(e => e.id == self.currentUser.locationId)[0]
      //self.location = userLocation
      eventBus.$on(CLOSE_MODAL_EVENT, () => {
        self.showMonthEndWarning = false
      })
    },
    beforeDestroy() {
    }
  }
</script>

<template>
  <div id="main" class="text-left">
    <div class="row">
      <div class="col">
        <h4>{{ viewTitle }}</h4>
        <div class="form-group row mt-4 d-flex justify-content-end clearfix mb-4">
          <!--v-bind:disabled="!currentUser.isAdmin"-->
          <select class="form-control col-md-4" v-model="location" required disabled>
            <option value="" disabled>-- Select district --</option>
            <option v-for="location in terminalLocations" :value="location">{{ location.name }}</option>
          </select>
        </div>
        <div class="d-flex justify-content-end clearfix mb-4" v-if="currentUser.canEnterBaseMovementDetails && location && location.status && location.id == currentUser.locationId">
          <button type="button" class="btn btn-primary" @click="showNewMovementDialog"><i class="icon-expand-plus" title="add new movement"></i></button>
        </div>
        <transition name="slide-fade">
          <app-alert v-if="alert.message && !showAddOrEditModal" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-modal :show="showCancelMovementModal" title="cancel movement entry" :closeModalEvent="cancelMovementModalEvent">
          <p class="font-weight-bold">Are you sure you want to cancel this movement entry?</p>
          <p class="font-weight-bold text-danger">
            Note: This action cannot be undone.
          </p>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="showCancelMovementModal = false" v-bind:disabled="isCancellingMovementEntry">No</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isCancellingMovementEntry }" @click.prevent="doCancelMovementEntry" v-bind:disabled="isCancellingMovementEntry">Yes<span class="spinner"></span></button>
          </template>
        </app-modal>
        <app-modal ref="excludeFromBillWarningModal" :show="showExcludeFromBillWarning" :static="true" size="medium" title="Exclude from NPA billing?" :closeModalEvent="closeExcludeFromBillingWarningModalEvent">
          <fieldset>
            <p class="font-weight-bold">
              Are you sure you want to exclude this movement from billing?
            </p>
            <p class="font-weight-bold text-danger">
              Note: This action may clear the data you have entered prior to this action
            </p>
          </fieldset>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click="showExcludeFromBillWarning = false; movement.excludeFromBill = false;">No</button>
            <button class="btn btn-primary d-inline mr-2" @click="showExcludeFromBillWarning = false; clearBillingEntries()">Yes</button>
          </template>
        </app-modal>
        <app-modal v-show="showAddOrEditModal" :title="movementModalTitle" size="xLarge" :static="true">
          <!--<transition v-for="(error, index) in errors" name="slide-fade" :key="error.title">
      <app-alert class="font-weight-bold" :type="errorAlertType" :message="error.title + ': ' + error.message" @alertclose="closeError(error)" />
    </transition>-->
          <transition name="slide-fade">
            <app-alert class="font-weight-bold" :type="errorAlertType" :message="mappedErrors" @alertclose="closeError" v-if="mappedErrors.length" />
          </transition>
          <form id="movementEntryForm" name="movementEntryForm" novalidate>
            <!--<ul class="errorMessages"></ul>-->
            <MovementEntryForm ref="movementEntryForm" :location="location" :movement="movement" :isDisabled="isSavingMovement || isSubmittingMovement" :invoiceCalculator="invoiceCalculator" :pageIndex="movementEntryFormPageIndex" :alert="alert" v-bind:isSubmittingMovement="isSubmittingMovement" v-bind:requireOptionalFields="requireOptionalFields" :emptyVessel="emptyVessel" :emptyBarge="emptyBarge"></MovementEntryForm>
          </form>
          <template v-slot:footer>
            <button class="btn btn-secondary d-inline mr-2" v-if="movementEntryFormPageIndex > 1" @click.prevent="goToPreviousPage" v-bind:disabled="isSavingMovement || isSubmittingMovement">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </svg>
            </button>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancel" v-bind:disabled="isSavingMovement || isSubmittingMovement">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>&nbsp;Cancel
            </button>
            <!--<button class="btn btn-success d-inline mr-2" v-bind:class="{ spin: isSavingMovement }" @click.prevent="submit(false)" v-bind:disabled="isSavingMovement || isSubmittingMovement">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
          <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
        </svg>&nbsp;Save for Later<span class="spinner"></span>
      </button>-->
            <button class="btn btn-primary d-inline mr-2" v-if="movementEntryFormPageIndex == 2 && userCanSubmitForm" v-bind:class="{ spin: isSubmittingMovement }" @click.prevent="submit(true)" v-bind:disabled="isSavingMovement || isSubmittingMovement">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
              </svg>&nbsp;{{ submitButtonText }}<span class="spinner"></span>
            </button>
            <button class="btn btn-secondary d-inline mr-2" v-if="movementEntryFormPageIndex < 2" @click.prevent="goToNextPage" v-bind:disabled="isSavingMovement">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
              </svg>
            </button>
          </template>
        </app-modal>
        <!--<div class="d-flex justify-content-end clearfix mb-4" v-if="allowSearchByMonth">-->
        <!--<input type="month" v-if="currentUser.canGenerateMonthlyReport" class="form-control col-md-4" />-->
        <!--<input type="month" class="form-control col-md-4" v-model="searchParams.monthAndYear" />
    <button class="btn btn-primary" @click="search">Seacrh</button>
  </div>-->
        <!--Filtering movements-->
        <div class="row">
          <div class="col">
            <div class="row mb-1">
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">location</label>
                  <select class="col-md-5 mr-0 form-control" v-model="searchParams.locationId">
                    <option value="">All</option>
                    <option v-for="location in terminalLocations" :value="location.id">{{ location.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">vessel</label>
                  <select class="col-md-5 mr-0 form-control" v-model="searchParams.vesselId">
                    <option value="">All</option>
                    <option v-for="vessel in vessels" :value="vessel.id">{{ vessel.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">barge</label>
                  <select class="col-md-5 mr-0 form-control" v-model="searchParams.bargeId">
                    <option value="">All</option>
                    <option v-for="barge in barges" :value="barge.id">{{ barge.name }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">user group</label>
                  <select class="col-md-5 mr-0 form-control" v-model="searchParams.userGroupId">
                    <option value="">All</option>
                    <option v-for="userGroup in userGroups" :value="userGroup.id">{{ userGroup.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">mode</label>
                  <select class="col-md-5 mr-0 form-control" v-model="searchParams.mode">
                    <option value="">All</option>
                    <option v-for="mode in Enums.MovementModes" :value="mode.value">{{ mode.description }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">reason</label>
                  <select class="col-md-5 mr-0 form-control" v-model="searchParams.reason">
                    <option value="">All</option>
                    <option v-for="reason in Enums.MovementReasons" :value="reason.value">{{ reason.description }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">Status</label>
                  <select class="col-md-5 mr-0 form-control" v-model="searchParams.status">
                    <option value="">All</option>
                    <option v-for="status in Enums.MovementStatus" :value="status.value">{{ status.description }}</option>
                  </select>
                </div>
              </div>

              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">From</label>
                  <input type="date" class="col-md-5 mr-0 form-control" v-model="searchParams.from" />
                </div>
              </div>
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">To</label>
                  <input type="date" class="col-md-5 mr-0 form-control" v-model="searchParams.to" />
                </div>
              </div> 
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">charge code</label>
                  <input type="text" class="col-md-5 mr-0 form-control" v-model="searchParams.chargeCode" />
                </div>
              </div>
              <!--<div class="d-flex justify-content-end clearfix mb-4" v-if="allowSearchByMonth">-->
              <!--<input type="month" v-if="currentUser.canGenerateMonthlyReport" class="form-control col-md-4" />-->
              <!--<input type="month" class="form-control col-md-4" v-model="searchParams.monthAndYear" />-->
              <!--<button class="btn btn-primary" @click="search">Seacrh</button>-->
              <!--</div>-->

            </div>
            <div class="form-group row text-center">
              <div class="col-sm-12 mr-0">
                <button class="button btn bttn btn-primary" @click.prevent="search">Search</button>
              </div>
            </div>
          </div>
        </div>

        <!--Movement filters end-->
        <!--<div class="row" v-if="result && result.length">
    <div class="col">
      <div>
        <MovementList ref="movementList" :tableProps="tableProps" :id="'movementList'+ currentTableKeyIndex" :key="'movementList-'+ currentTableKeyIndex" :movements="result" emptyTableMessage="There are no movements that match the search criteria" />
      </div>
    </div>
  </div>-->
        <MovementList ref="movementList" :tableProps="movementListProps" :id="'movementList'+ currentTableKeyIndex" :key="'movementList-'+ currentTableKeyIndex" :movements="movements" :emptyTableMessage="emptyTableMessage" />

       <!-- <MovementList ref="movementList" :tableProps="movementList" :id="'movementList'+ currentTableKeyIndex" :key="'movementList-'+ currentTableKeyIndex" :movements="movements" :emptyTableMessage="emptyTableMessage" />-->
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  input:invalid {
    border: 1px solid red !important;
  }
</style>

<script>
  import store from '@/store'
  import { mapState, mapGetters } from 'vuex'
  import { toStatusDescription } from '@/filters/enum'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'
  import { CANCEL_MOVEMENT_EVENT, CLOSE_MOVEMENT_CANCEL_MODAL_EVENT } from '@/utils/constants'

  import { FETCH_LOCATIONS, FETCH_VESSELS, FETCH_BARGES, FETCH_USERGROUPS } from '@/store/action-type'

  import { movementService, reportService } from '@/services'


  import Enums from '@/utils/enums'

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import MovementEntryForm from '@/components/movement/EntryForm'
  import MovementList from '@/components/movement/MovementList'

  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'
  import MovementMixin from '@/mixins/MovementMixin'

  export default {
    name: 'Main',
    components: {
      AppAlert,
      AppModal,
      MovementEntryForm,
      MovementList
    },
    mixins: [AlertMixin, ErrorMixin, MovementMixin],
    props: ['movements', 'location', 'fetchMovementsEvent', 'viewTitle', 'allowSearchByMonth'],
    data: () => ({
      closeExcludeFromBillingWarningModalEvent: 'CLOSE_BILLING_WARNING_MODAL_EVENT',
      emptyTableMessage: '',
      searchParams: {
        from: '',
        to: '',
        locationId: '',
        vesselId: '',
        bargeId: '',
        userGroupId: '',
        mode: '',
        reason: '',
        status: '',
        chargeCode: ''
      },
      result: [],
      currentTableKeyIndex: 1,
      tableProps: {
        order: null,
        page: null,
        layout: 'list'
      },
      Enums: Enums,
      movementToCancelId: null,
      showCancelMovementModal: false,
      isCancellingMovementEntry: false,
      cancelMovementModalEvent: CLOSE_MOVEMENT_CANCEL_MODAL_EVENT,
      cancelMovementEvent: CANCEL_MOVEMENT_EVENT
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        locations: state => state.locationModule.locations,
        vessels: state => state.vesselModule.vessels,
        barges: state => state.bargeModule.barges,
        userGroups: state => state.userGroupModule.userGroups
      }),
      ...mapGetters([
        'terminalLocations',
      ]),
      mappedErrors() {
        return this.errors.map(error => error.title + ': ' + error.message)
      }
    },
    watch: {
      location: {
        handler(newLocation, oldLocation) {
          this.search()
        },
        immediate: false
      }
    },
    methods: {
      showNewMovementDialog() {
        this.resetData()
        this.movement.fromLocationId = this.location.id
        this.movementModalTitle = 'create movement'
        this.showAddOrEditModal = true
      },
      resetData() {
        this.searchParams.locationId = ''
        this.searchParams.vesselId = ''
        this.searchParams.bargeId = ''
        this.searchParams.userGroupId = ''
      },
      resetTableData() {
        this.result.splice(0, this.result.length)
      },
      //search(month) {
      //  const self = this
      //  eventBus.$emit(Events.LongOperationStarted, 'loading data')
      //  store.dispatch(self.fetchMovementsEvent, { locationId: self.location.id, month: self.searchParams.monthAndYear }).then(() => {
      //    self.closeMessage()
      //    self.emptyTableMessage = 'There are no movements to display'
      //    self.currentTableKeyIndex += 1
      //  }).catch((error) => {
      //    self.handleError(error)
      //    self.emptyTableMessage = 'An error occured while trying to fetch data'
      //  }).finally(() => {
      //    self.$nextTick(() => {
      //      self.$refs.movementList.refresh();
      //    });
      //    eventBus.$emit(Events.LongOperationCompleted)
      //  })
      //},
      search() {
        const self = this
        self.resetTableData();
        self.closeMessage();
        eventBus.$emit(Events.LongOperationStarted, 'loading data')
       
      const params = {
        locationId: self.searchParams.locationId || self.location.id,
        from: self.searchParams.from,
        to: self.searchParams.to,
        vesselId: self.searchParams.vesselId,
        bargeId: self.searchParams.bargeId,
        userGroupId: self.searchParams.userGroupId,
        mode: self.searchParams.mode,
        reason: self.searchParams.reason,
        status: self.searchParams.status,
        chargeCode: self.searchParams.chargeCode
      };

        store.dispatch(self.fetchMovementsEvent, params).then(() => {
            self.closeMessage()
            self.emptyTableMessage = 'There are no movements to display'
            self.currentTableKeyIndex += 1
          }).catch((error) => {
            self.handleError(error)
            self.emptyTableMessage = 'An error occured while trying to fetch data'
          }).finally(() => {
            self.$nextTick(() => {
              self.$refs.movementList.refresh();
            });
            eventBus.$emit(Events.LongOperationCompleted)
          })
        },

      //search() {
      //  const self = this;
      //  self.resetTableData();
      //  self.closeMessage();
      //  eventBus.$emit(Events.LongOperationStarted, 'loading data');

      //  const params = {
      //    locationId: self.location.id,
      //    from: self.searchParams.from,
      //    to: self.searchParams.to,
      //    vesselId: self.searchParams.vesselId,
      //    bargeId: self.searchParams.bargeId,
      //    userGroupId: self.searchParams.userGroupId,
      //    mode: self.searchParams.mode,
      //    reason: self.searchParams.reason,
      //    status: self.searchParams.status,
      //    chargeCode: self.searchParams.chargeCode
      //  };

      //  store.dispatch(self.fetchMovementsEvent, params)
      //    .then((result) => {
      //      if (result && result.data && result.data.items && result.data.items.length) {
      //        self.result = [...result.data.items];
      //        self.$nextTick(() => {
      //          self.$refs.movementList.refresh();
      //        });
      //      } else {
      //        self.showErrorMessage("There are no movements that match the search criteria");
      //      }
      //    })
      //    .catch((error) => {
      //      self.showErrorMessage(error);
      //    })
      //    .finally(() => {
      //      eventBus.$emit(Events.LongOperationCompleted);
      //    });
      //},
      doCancelMovementEntry() {
        const self = this
        //eventBus.$emit(Events.LongOperationStarted, 'cancelling movement entry')
        self.isCancellingMovementEntry = true
        movementService.cancelMovementEntry(self.movementToCancelId).then(() => {
          self.closeMessage()
          self.showCancelMovementModal = false
          self.currentTableKeyIndex += 1
        }).catch((error) => {
          self.handleError(error)
          self.emptyTableMessage = 'An error occured while trying to cancel the movement entry'
        }).finally(() => {
          self.$nextTick(() => {
            self.$refs.movementList.refresh();
          });
          self.isCancellingMovementEntry = false
          /*eventBus.$emit(Events.LongOperationCompleted)*/
        })
      }
    },
    filters: {
      toStatusDescription
    },
    mounted() {
      const self = this
      this.$refs.movementEntryForm.closeMessage = this.closeMessage

      eventBus.$on(this.closeExcludeFromBillingWarningModalEvent, () => {
        self.movement.excludeFromBill = false
        self.showExcludeFromBillWarning = false
      })

      eventBus.$on(this.cancelMovementEvent, (movementId) => {
        self.movementToCancelId = movementId
        self.showCancelMovementModal = true
      })

      eventBus.$on(this.cancelMovementModalEvent, () => {
        self.isCancellingMovementEntry = false
        self.showCancelMovementModal = false
        self.movementToCancelId = null
      })
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, 'loading data')
      const tasks = []
      tasks.push(store.dispatch(FETCH_LOCATIONS))
      tasks.push(store.dispatch(FETCH_VESSELS))
      tasks.push(store.dispatch(FETCH_BARGES))
      tasks.push(store.dispatch(FETCH_USERGROUPS))
      Promise.all(tasks).then(() => {
        next()
      }).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
        })
      }).finally(() => {
        eventBus.$emit(Events.LongOperationCompleted)
      })
    },
    beforeRouteUpdate(to, from, next) {
      this.tableProps.order = null;
      this.tableProps.page = null;
      this.tableProps.layout = null;
      this.closeMessage();
      next();
    },
    beforeDestroy() {
      eventBus.$off(this.closeExcludeFromBillingWarningModalEvent)
      eventBus.$off(this.cancelMovementEvent)
      eventBus.$off(this.cancelMovementModalEvent)
    }
  }
</script>

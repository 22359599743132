<template>
  <MainView :movements="movementsPendingUserAction" :location="location" :fetchMovementsEvent="fetchMovementsPendingUserActionEvent" viewTitle="Movements Pending My Action" :allowSearchByMonth="false"></MainView>
</template>

<script>
  import store from '@/store'
  import { mapState } from 'vuex'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import { FETCH_MOVEMENTS_PENDING_USER_ACTION, FETCH_VESSELS, FETCH_USERGROUPS, FETCH_LOCATIONS, FETCH_BARGES, FETCH_RATES } from '@/store/action-type'

  import MainView from '@/components/movement/Main'

  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  export default {
    name: 'MovementView',
    components: {
      MainView
    },
    mixins: [AlertMixin, ErrorMixin],
    data: () => ({
      location: {},
      fetchMovementsPendingUserActionEvent: FETCH_MOVEMENTS_PENDING_USER_ACTION
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        movementsPendingUserAction: state => state.movementModule.movementsPendingUserAction,
        locations: state => state.locationModule.locations,
      })
    },
    mounted() {
      const self = this
      let userLocation = self.locations.filter(e => e.id == self.currentUser.locationId)[0]
      self.location = userLocation
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, 'loading data')
      const tasks = []
      tasks.push(store.dispatch(FETCH_VESSELS))
      tasks.push(store.dispatch(FETCH_BARGES))
      tasks.push(store.dispatch(FETCH_RATES))
      tasks.push(store.dispatch(FETCH_LOCATIONS))
      tasks.push(store.dispatch(FETCH_USERGROUPS))
      Promise.all(tasks).then(() => {
        next()
      }).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
        })
      }).finally(() => {
        eventBus.$emit(Events.LongOperationCompleted)
      })
    }
  }
</script>

'use strict'

import Enums from '@/utils/enums'

export default class InvoiceCalculator {
  rates
  enterpriseLocationId
  movementDate
  movementTime
  dateIsWeekendOrPublicHoliday
  mode
  vesselGRT
  bargeCBM
  cargoWeight
  fuelInLitres
  numberOfPassengers
  fuelDensity

  constructor (rates, enterpriseLocationId, movement) {
    this.rates = rates || []
    this.enterpriseLocationId = enterpriseLocationId || null
    this.vesselGRT = movement.vessel.grt || 0
    this.bargeCBM = movement.barge.CBM || 0
    this.cargoWeight = movement.cargoWeight || 0
    this.fuelInLitres = movement.fuelROB || 0
    this.numberOfPassengers = movement.numberOfPassengers
    this.movementDate = movement.date || ''
    this.movementTime = movement.time || ''
    this.dateIsWeekendOrPublicHoliday = movement.dateIsWeekendOrPublicHoliday || false
    this.mode = movement.mode
    this.fuelDensity = movement.fuelDensity || 0
  }

  getRatesForType (rateType) {
    const movementDate = new Date(this.movementDate)
    let matchingRates = this.sortedRates.filter(r => r.rateType === rateType && movementDate >= new Date(r.startDate))
    if (matchingRates.length) {
      if (this.enterpriseLocationId) {
        const enterpriseLocationMatchinRates = matchingRates.filter(r => r.enterpriseLocationId === this.enterpriseLocationId)
        if (enterpriseLocationMatchinRates.length) {
          return enterpriseLocationMatchinRates[0].value
        }
      }
      return matchingRates[0].value
    }
    return 0
  }

  get sortedRates () {
    return [...this.rates].sort((x, y) => {
      const dateX = new Date(x.startDate)
      const dateY = new Date(y.startDate)

      if (dateX > dateY) {
        return -1
      }
      if (dateX < dateY) {
        return 1
      }
      return 0
    })
  }

  get pobRate () {
    return this.getRatesForType(0)
  }

  get pilotageMultiplierRate () {
    return this.getRatesForType(1)
  }

  get conservancyMultiplierRate () {
    return this.getRatesForType(2)
  }

  get footageMultiplierPercentRate () {
    return this.getRatesForType(3)
  }

  get lightDuesRate () {
    return this.getRatesForType(4)
  }

  get overtimeRate () {
    return this.getRatesForType(5)
  }

  get garbageScavendingRate () {
    return this.getRatesForType(6)
  }

  get publicHolidayRate () {
    return this.getRatesForType(7)
  }

  get taxRatePercentRate () {
    return this.getRatesForType(99)
  }

  get fuelTonnage () {
    return (this.fuelInLitres * this.fuelDensity) / 1000
  }

  get chargeableTonnage () {
    // Add vessel cargo tonnage to it
    return this.vesselGRT + this.bargeCBM + this.cargoWeight + this.fuelTonnage
  }

  get pob () {
    return this.numberOfPassengers * this.pobRate
  }

  get pilotage () {
    return this.chargeableTonnage * this.pilotageMultiplierRate
  }

  get conservancy () {
    return this.chargeableTonnage * this.conservancyMultiplierRate
  }

  get footage () {
    return this.pilotage * this.footageMultiplierPercentRate
  }

  get lightDues () {
    if (this.mode === Enums.MovementModes.Arrival) {
      return this.vesselGRT * this.lightDuesRate
    }
    return 0.00
  }

  get weekendOrPublicHolidayCharge () {
    if (this.movementDate) {
      if (this.dateIsWeekendOrPublicHoliday) {
        return this.publicHolidayRate
      }
    }
    return 0.00
  }

  get overtimeCharge () {
    if (this.movementDate && this.movementTime) {
      let movementDate = new Date(Date.parse(this.movementDate))
      const splittedTime = this.movementTime.split(':')
      movementDate.setHours(splittedTime[0])
      movementDate.setMinutes(splittedTime[1])
      //var tzoffset = movementDate.getTimezoneOffset() * 60000; //offset in milliseconds
      //movementDate = (new Date(movementDate - tzoffset)).toISOString().slice(0, -1)

      var movementHour = movementDate.getHours()
      var movementMinute = movementDate.getMinutes()

      if (movementHour < 7 || (movementHour === 7 && movementMinute < 30) || movementHour > 16 || (movementHour === 16 && movementMinute > 0)) {
        return this.overtimeRate
      }
    }
    return 0.00
  }

  get garbageScavendingCharge () {
    if (this.mode === Enums.MovementModes.Arrival) {
      return this.garbageScavendingRate
    }
    return 0.00
  }

  get total () {
    return this.pob + this.pilotage + this.conservancy + this.footage + this.lightDues + this.weekendOrPublicHolidayCharge + this.overtimeCharge + this.garbageScavendingCharge
  }

  get VAT () {
    return this.total * (this.taxRatePercentRate / 100)
  }

  get totalWithVAT () {
    return this.total + this.VAT
  }
}

<template>
  <div id="voyageMonthlyReportView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>Pilotage Cost Report</h4>
        <!--Filter parameters-->
        <div class="row">
          <div class="col">
            <div class="row mb-1">
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">location</label>
                  <select class="col-md-5 mr-0 form-control" v-model="searchparms.locationId">
                    <option value="">All</option>
                    <option v-for="location in terminalLocations" :value="location.id">{{ location.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">vessel</label>
                  <select class="col-md-5 mr-0 form-control" v-model="searchparms.vesselId">
                    <option value="">All</option>
                    <option v-for="vessel in vessels" :value="vessel.id">{{ vessel.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">barge</label>
                  <select class="col-md-5 mr-0 form-control" v-model="searchparms.bargeId">
                    <option value="">All</option>
                    <option v-for="barge in barges" :value="barge.id">{{ barge.name }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">user group</label>
                  <select class="col-md-5 mr-0 form-control" v-model="searchparms.userGroupId">
                    <option value="">All</option>
                    <option v-for="userGroup in userGroups" :value="userGroup.id">{{ userGroup.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">mode</label>
                  <select class="col-md-5 mr-0 form-control" v-model="searchparms.mode">
                    <option value="">All</option>
                    <option v-for="mode in Enums.MovementModes" :value="mode.value">{{ mode.description }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">reason</label>
                  <select class="col-md-5 mr-0 form-control" v-model="searchparms.reason">
                    <option value="">All</option>
                    <option v-for="reason in Enums.MovementReasons" :value="reason.value">{{ reason.description }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">Status</label>
                  <select class="col-md-5 mr-0 form-control" v-model="searchparms.status">
                    <option value="">All</option>
                    <option v-for="status in Enums.MovementStatus" :value="status.value">{{ status.description }}</option>
                  </select>
                </div>
              </div>

              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">From</label>
                  <input type="date" class="col-md-5 mr-0 form-control" v-model="searchparms.from" />
                </div>
              </div>
              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">To</label>
                  <input type="date" class="col-md-5 mr-0 form-control" v-model="searchparms.to" />
                </div>
              </div>

              <div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">charge code</label>
                  <input type="text" class="col-md-5 mr-0 form-control" v-model="searchparms.chargeCode" />
                </div>
              </div>

              <!--<div class="col-md-4 mr-0">
                <div class="row">
                  <label class="col-md-4 mr-0 text-left text-secondary">month</label>
                  <input type="month" class="col-md-5 mr-0 form-control" v-model="searchparms.monthAndYear" value="getDefaultMonthYear()" />
                </div>
              </div>-->

            </div>
            <div class="form-group row text-center">
              <div class="col-sm-12 mr-0">
                <button class="button btn bttn btn-primary" @click="generateReport">Search</button>
              </div>
            </div>
          </div>
        </div>


        <!--Filter parameter ends-->

        <!--<div class="row" v-if="result && result.length">
          <div class="col">
            <div>
              <MovementList ref="searchedMovementList" :tableProps="tableProps" :id="'searchedMovementList'+ currentTableKeyIndex" :key="'searchedMovementList-'+ currentTableKeyIndex" :movements="result" emptyTableMessage="There are no movements that match the search criteria" />
            </div>
          </div>
        </div>-->

        <!--<div class="d-flex justify-content-end clearfix mb-4">-->
        <!--<input type="month" v-if="currentUser.canGenerateMonthlyReport" class="form-control col-md-4" />-->
        <!--<input type="month" class="form-control col-md-4"  v-model="searchparms.monthAndYear" value="getDefaultMonthYear()" />
    <button class="btn btn-primary" @click="generateReport">Search</button>
  </div>-->
        <transition name="slide-fade">
          <app-alert v-if="alert.message && !showAddOrEditModal" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <MonthlyMovementList ref="monthlyMovementList" :tableProps="monthlyMovementListProps" :id="'monthlyMovementList'+ currentTableKeyIndex" :key="'MonthlyMovementList-'+ currentTableKeyIndex" :movements="movements" />      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  input:invalid {
    border: 1px solid red !important;
  }
</style>

<script>
  import store from '@/store'
  import { mapState, mapGetters } from 'vuex'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'
  import Enums from '@/utils/enums'
  import { ALERT_TYPE_ERROR } from '@/utils/constants'

  import { FETCH_LOCATIONS, FETCH_VESSELS, FETCH_BARGES, FETCH_USERGROUPS } from '@/store/action-type'

  import { movementService } from '@/services'

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import MonthlyMovementList from '@/components/movement/MonthlyMovementList'

  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  export default {
    name: 'MonthlyVoyageReportView',
    components: {
      AppAlert,
      AppModal,
      MonthlyMovementList
    },
    mixins: [AlertMixin, ErrorMixin],
    data: () => ({
      location: {},
      
      searchparms: {
        locationId: '',
        vesselId: '',
        bargeId: '',
        userGroupId: '',
        mode: '',
        reason: '',
        status: '',
        chargeCode: '',
        from: '',
        to: '',
        //monthAndYear: `${new Date().getFullYear()}-${('0' + (new Date().getMonth() + 1)).slice(-2)}`
      },
      movements: [],
      monthlyMovementListProps: {
        order: null,
        page: null,
        search: null,
        layout: 'list'
      },
      currentTableKeyIndex: 1,
      errorAlertType: ALERT_TYPE_ERROR,
      Enums: Enums
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        locations: state => state.locationModule.locations,
        vessels: state => state.vesselModule.vessels,
        barges: state => state.bargeModule.barges,
        userGroups: state => state.userGroupModule.userGroups
      }),
      ...mapGetters([
        'terminalLocations',
      ]),
    },
      ...mapGetters([
        'terminalLocations'
      ]),
      //invoiceCalculator() {
      //  if (this.location) {
      //    return new InvoiceCalculator(this.allRates, this.location.id, this.movement)
      //  }
      //  else {
      //    return new InvoiceCalculator(this.allRates, 0, this.movement)
      //  }
      //},
    //},
    methods: {
      resetData() {
        this.searchParams.locationId = ''
        this.searchParams.vesselId = ''
        this.searchParams.bargeId = ''
        this.searchParams.userGroupId = ''
      },
      generateReport() {
        const self = this
        self.movements.splice(0, self.movements.length)
        eventBus.$emit(Events.LongOperationStarted, 'generating report')
        movementService.searchPilotage(self.searchparms).then((result) => {
          self.movements = [...result.data.items]
          self.currentTableKeyIndex += 1
          self.$nextTick(() => {
            self.$refs.monthlyMovementList.refresh();
          });
        }).catch((error) => {
          self.handleError(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      getDefaultMonthYear() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = ('0' + (currentDate + 1)).slice(-2); // Ensure two-digit month

        return `${year}-${month}`;
      }
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, 'loading data')
      const tasks = []
      tasks.push(store.dispatch(FETCH_LOCATIONS))
      tasks.push(store.dispatch(FETCH_VESSELS))
      tasks.push(store.dispatch(FETCH_BARGES))
      tasks.push(store.dispatch(FETCH_USERGROUPS))
      Promise.all(tasks).then(() => {
        next()
      }).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
        })
      }).finally(() => {
        eventBus.$emit(Events.LongOperationCompleted)
      })
    },
    mounted() {
      const self = this
      let userLocation = self.locations.filter(e => e.id == self.currentUser.locationId)[0]
      self.location = userLocation
      self.$refs.monthlyMovementList.refresh();
      self.generateReport()
    
    },
    beforeDestroy() {
    }
  }
</script>

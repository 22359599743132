<script>
  import store from '@/store'
  import { mapState, mapGetters } from 'vuex'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'
  import { ALERT_TYPE_ERROR, CLOSE_MODAL_EVENT, EDIT_MOVEMENT_EVENT  } from '@/utils/constants'
  import Enums from '@/utils/enums'
  import { ServerValidationError, AppError } from '@/utils/errors'


  import InvoiceCalculator from '@/types/invoiceCalculator'
  import { movementService, publicHolidayService } from '@/services'

  import { CREATE_MOVEMENT, UPDATE_FUEL_DATA, UPDATE_CARGO_DATA, SUBMIT_MOVEMENT } from '@/store/action-type'


  import MovementEntryForm from '@/components/movement/EntryForm'

  export default {
    components: {
      MovementEntryForm
    },
    data() {
      return { 
        movement: {
          id: null,
          vessel: '',
          barge: '',
          movementTypeId: '',
          mode: '',
          departureDate: '',
          departureTime: '',
          arrivalDate: '',
          arrivalTime: '',
          dateIsWeekendOrPublicHoliday: false,
          userGroupId: '',
          fromLocationId: '',
          toLocationId: '',
          reason: 0,
          chargeCode: '',
          fuelROB: '',
          fuelDensity: '',
          fuelTonnage: '',
          tenFeetCargoQuantity: '',
          twentyFeetCargoQuantity: '',
          fortyFeetCargoQuantity: '',
          materialCargoTonnage: '',
          numberOfPassengers: '',
          excludeFromBill: false,
          supportingDocuments: [],
          status: Enums.MovementStatus.Undefined.value
        },
        movementListProps: {
          order: null,
          page: null,
          search: null,
          layout: 'list'
        },
        movementModalTitle: '',
        movementEntryFormPageIndex: 1,
        currentTableKeyIndex: 1,
        emptyVessel: {},
        emptyBarge: {},
        showExcludeFromBillWarning: false,
        showAddOrEditModal: false,
        errorAlertType: ALERT_TYPE_ERROR,
        isSavingMovement: false,
        isSubmittingMovement: false,
        requireOptionalFields: false
      }
    },
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        vessels: state => state.vesselModule.vessels,
        barges: state => state.bargeModule.barges,
        locations: state => state.locationModule.locations,
      }),
      ...mapGetters([
        'allRates',
        'terminalLocations',
        'activeTerminalLocations'
      ]),
      invoiceCalculator() {
        if (this.location && this.location.id) {
          return new InvoiceCalculator(this.allRates, this.location.id, this.movement)
        }
        else {
          return new InvoiceCalculator(this.allRates, 0, this.movement)
        }
      },
      userCanSubmitForm() {
        if ((this.movement.status == Enums.MovementStatus.Undefined.value && this.currentUser.canEnterBaseMovementDetails) ||
          (this.movement.status == Enums.MovementStatus.AwaitingFuelAnalyst.value && this.currentUser.canEnterFuelDetails) ||
          (this.movement.status == Enums.MovementStatus.AwaitingCargoAnalyst.value && this.currentUser.canEnterCargoDetails) ||
          (this.movement.status == Enums.MovementStatus.AwaitingAnalystReview.value && this.currentUser.canSubmitMovement) ||
          (this.movement.status == Enums.MovementStatus.Calculated.value && this.currentUser.canSubmitMovement) ||
          (this.movement.status == Enums.MovementStatus.Calculated.value && this.currentUser.canEnterCargoDetails))
          return true
        return false
      },
      submitButtonText() {
        //if (this.movement.status == Enums.MovementStatus.Undefined.value && !this.movement.excludeFromBill)
        //  return 'Send to fuel analyst'
        //if (this.movement.status == Enums.MovementStatus.AwaitingFuelAnalyst.value)
        //  return 'Send to cargo analyst'
        //if (this.movement.status == Enums.MovementStatus.AwaitingCargoAnalyst.value)
        //  return 'Send for analyst review'
        //if (this.movement.status == Enums.MovementStatus.AwaitingAnalystReview.value || this.movement.excludeFromBill)
        //  return 'Submit'
        //return ''

        return 'Submit'
      }
    },
    watch: {
      //'movement.mode'(newValue, oldValue) {
      //  if (!newValue) {
      //    this.movement.toLocationId = this.location.id
      //  }
      //  else {
      //    this.movement.fromLocationId = this.location.id
      //  }
      //},
      'movement.departureDate'(newDate, oldDate) {
        const self = this
        if (newDate) {
          let date = new Date(Date.parse(newDate))
          if (date.getDay() === 6 || date.getDay() === 0) {
            self.movement.dateIsWeekendOrPublicHoliday = true
            return
          }
          date.setHours(0)
          date.setMinutes(0)
          date.setSeconds(0)
          var tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
          date = (new Date(date - tzoffset)).toISOString().slice(0, -1);
          eventBus.$emit(Events.LongOperationStarted, 'checking...')
          publicHolidayService.checkDateIsPublicHoliday(date).then((result) => {
            self.movement.dateIsWeekendOrPublicHoliday = result.data
          }).catch((error) => {
            alert(error)
            self.movement.dateIsWeekendOrPublicHoliday = false
          }).finally(() => {
            eventBus.$emit(Events.LongOperationCompleted)
          })
        }
      },
      'movement.excludeFromBill'(newValue, oldValue) {
        if (newValue && this.movement.status == Enums.MovementStatus.Undefined.value) {
          this.$refs.excludeFromBillWarningModal.zIndex = 9998
          this.showExcludeFromBillWarning = true
        }
      }
    },
    methods: {
      resetData() {
        this.movement.id = null
        this.movement.departureDate = ''
        this.movement.departureTime = ''
        this.movement.arrivalDate = ''
        this.movement.arrivalTime = ''
        this.movement.dateIsWeekendOrPublicHoliday = false
        this.movement.vessel = ''
        this.movement.name = ''
        this.movement.mode = '' //Enums.MovementModes.Departure.value
        this.movement.status = Enums.MovementStatus.Undefined.value
        this.movement.fromLocationId = ''
        this.movement.toLocationId = ''
        this.clearBillingEntries()
        this.movement.excludeFromBill = false
        this.movement.supportingDocuments.splice(0, this.movement.supportingDocuments.length)
        this.movementEntryFormPageIndex = 1
        let errorList = $("ul.errorMessages");
        errorList.empty();
      },
      clearBillingEntries() {
        this.movement.barge = ''
        this.movement.fuelROB = ''
        this.movement.fuelDensity = ''
        this.movement.fuelTonnage = ''
        this.movement.cargoWeight = ''
        this.movement.numberOfPassengers = ''
        this.movement.userGroupId = ''
        this.movement.reason = ''
        this.movement.chargeCode = ''
        this.movement.tenFeetCargoQuantity = 0
        this.movement.twentyFeetCargoQuantity = 0
        this.movement.fortyFeetCargoQuantity = 0
        this.movement.materialCargoTonnage = ''
      },
      getVessel(id) {
        return this.vessels.filter(vessel => vessel.id == id)[0]
      },
      getBarge(id) {
        if (id) {
          return this.barges.filter(barge => barge.id == id)[0]
        }
        return this.emptyBarge
      },
      editMovement(selectedMovementId) {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, 'loading data')
        self.resetData()
        movementService.getMovement(selectedMovementId).then((result) => {
          let movement = result.data
          self.movement.id = movement.id
          self.movement.mode = movement.mode
          self.movement.vessel = self.getVessel(movement.vesselId)
          self.movement.barge = self.getBarge(movement.bargeId)

          if (movement.departureDate) {
            let movementDate = new Date(Date.parse(movement.departureDate))
            self.movement.departureDate = `${movementDate.getFullYear()}-${('0' + (movementDate.getMonth() + 1)).slice(-2)}-${('0' + movementDate.getDate()).slice(-2)}`
            self.movement.departureTime = movementDate.toLocaleTimeString('en-GB');
          }

          if (movement.arrivalDate) {
            let movementDate = new Date(Date.parse(movement.arrivalDate))
            self.movement.arrivalDate = `${movementDate.getFullYear()}-${('0' + (movementDate.getMonth() + 1)).slice(-2)}-${('0' + movementDate.getDate()).slice(-2)}`
            self.movement.arrivalTime = movementDate.toLocaleTimeString('en-GB');
          }

          self.movement.name = ''
          self.movement.fuelROB = movement.fuelROB || ''
          self.movement.fuelDensity = movement.fuelDensity || ''
          self.movement.tenFeetCargoQuantity = movement.tenFeetCargoQuantity || 0
          self.movement.twentyFeetCargoQuantity = movement.twentyFeetCargoQuantity || 0
          self.movement.fortyFeetCargoQuantity = movement.fortyFeetCargoQuantity || 0
          self.movement.materialCargoTonnage = movement.materialCargoTonnage 
          self.movement.numberOfPassengers = movement.numberOfPassengers
          self.movement.userGroupId = movement.userGroupId
          self.movement.fromLocationId = movement.fromLocationId
          self.movement.toLocationId = movement.toLocationId
          self.movement.reason = movement.reason
          self.movement.chargeCode = movement.chargeCode
          self.movement.status = movement.status
          self.movement.excludeFromBill = movement.excludeFromBill
          self.movement.supportingDocuments = [...movement.supportingDocuments]
          self.movementModalTitle = 'edit movement'

          self.movement.fuelTonnage = movement.fuelTonnage || ''
          self.movement.chargeableTonnage = movement.chargeableTonnage || ''
          self.movement.pobCharge = movement.pobCharge || ''
          self.movement.pilotageCharge = movement.pilotageCharge || ''
          self.movement.conservancyCharge = movement.conservancyCharge || ''
          self.movement.footageCharge = movement.footageCharge || ''
          self.movement.lightDues = movement.lightDues || ''
          self.movement.weekendOrPublicHolidayCharge = movement.weekendOrPublicHolidayCharge || ''
          self.movement.overtimeCharge = movement.overtimeCharge || ''
          self.movement.garbageScavendingCharge = movement.garbageScavendingCharge || ''
          self.movement.totalBeforeVAT = movement.totalBeforeVAT || ''
          self.movement.vat = movement.vat || ''
          self.movement.totalAfterVAT = movement.totalAfterVAT || ''

          self.movement.id = movement.id
          self.showAddOrEditModal = true
        }).catch((error) => {
          self.handleError(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      submit(sendToNext = false) {
        const self = this
        if (sendToNext) {
          self.requireOptionalFields = true
        }
        this.$nextTick(() => {
          if (self.validateMovementForm()) {
            if (sendToNext) {
              self.isSubmittingMovement = true
            }
            else {
              self.isSavingMovement = true
            }
            let movement = { ...self.movement }
            movement.locationId = self.location.id
            movement.vesselId = movement.vessel.id
            if (movement.barge) {
              movement.bargeId = movement.barge.id
            }
            //movement.mode = movement.mode.value
            if (movement.departureDate) {
              movement.departureDate = new Date(Date.parse(movement.departureDate))
              let splittedTime = movement.departureTime.split(":")
              movement.departureDate.setHours(splittedTime[0])
              movement.departureDate.setMinutes(splittedTime[1])
              var tzoffset = movement.departureDate.getTimezoneOffset() * 60000; //offset in milliseconds
              movement.departureDate = (new Date(movement.departureDate - tzoffset)).toISOString().slice(0, -1);
            }

            if (movement.arrivalDate) {
              movement.arrivalDate = new Date(Date.parse(movement.arrivalDate))
              let splittedTime = movement.arrivalTime.split(":")
              movement.arrivalDate.setHours(splittedTime[0])
              movement.arrivalDate.setMinutes(splittedTime[1])
              var tzoffset = movement.arrivalDate.getTimezoneOffset() * 60000; //offset in milliseconds
              movement.arrivalDate = (new Date(movement.arrivalDate - tzoffset)).toISOString().slice(0, -1);
            }
            else {
              movement.arrivalTime = ''
            }
            movement.files = []

            //movement.date = movement.date.toISOString()
            self.closeMessage()
            movement.sendToNext = sendToNext
            let actionType = CREATE_MOVEMENT
            let successMessage = "Movement created successfully"

            if (this.movement.status == Enums.MovementStatus.AwaitingFuelAnalyst.value) {
              actionType = UPDATE_FUEL_DATA
              movement = {
                id: this.movement.id,
                files: []
              }
              movement.fuelROB = this.movement.fuelROB
              movement.fuelDensity = this.movement.fuelDensity
            }
            else if (this.movement.status == Enums.MovementStatus.AwaitingCargoAnalyst.value) {
              actionType = UPDATE_CARGO_DATA
              movement = {
                id: this.movement.id,
                files: []
              }
              movement.tenFeetCargoQuantity = this.movement.tenFeetCargoQuantity
              movement.twentyFeetCargoQuantity = this.movement.twentyFeetCargoQuantity
              movement.fortyFeetCargoQuantity = this.movement.fortyFeetCargoQuantity
              movement.materialCargoTonnage = this.movement.materialCargoTonnage
            }
            else if (this.movement.status == Enums.MovementStatus.AwaitingAnalystReview.value) {
              actionType = SUBMIT_MOVEMENT
              //movement = {
              //  id: this.movement.id,
              //  files: []
              //}
            }
            else if (this.movement.status === Enums.MovementStatus.Calculated.value) {
            
              if (this.movement.materialCargoTonnage === null) {
                actionType = UPDATE_FUEL_DATA //UPDATE_CARGO_DATA;
              }
              //else if (this.movement.materialCargoTonnage !== null) {
              //  actionType = UPDATE_CARGO_DATA;
              //}
              else{
                actionType = SUBMIT_MOVEMENT;
              }

            }
            const dropZone = this.$refs.movementEntryForm.$refs.supportingDocumentsDropzone
            if (dropZone.getQueuedFiles().length) {
              for (const file of this.$refs.movementEntryForm.$refs.supportingDocumentsDropzone.getQueuedFiles()) {
                movement.files.push({
                  name: 'files',
                  content: file
                })
              }
            }
            store.dispatch(actionType, movement).then(() => {
              self.showSuccessMessage(successMessage)
              self.currentTableKeyIndex += 1
              self.$nextTick(() => {
                self.$refs.movementList.refresh();
              });
              self.showAddOrEditModal = false
            }).catch((error) => {
              if (error instanceof ServerValidationError) {
                for (const e of error.errorList) {
                  self.errors.push({
                    title: 'validation error',
                    message: e
                  })
                }
              }
              else if (error instanceof AppError) {
                self.errors.push({
                  title: 'server error',
                  message: error.message
                })
              }
              else {
                self.errors.push({
                  title: 'Server error',
                  message: error.message
                })
              }
            }).finally(() => {
              self.isSavingMovement = false
              self.isSubmittingMovement = false
              self.requireOptionalFields = false
            })
          }
          else {
            //self.isSavingMovement = false
            //self.isSubmittingMovement = false
            self.requireOptionalFields = false
          }
        })
      },
      validateMovementForm() {
        const self = this
        self.errors.splice(0, self.errors.length)
        let form = $('#movementEntryForm')
        var invalidFields = form.find(":invalid").not('fieldset').each(function (index, node) {
          // Find the field's corresponding label
          var label = $(node).attr('errorLabel')
          if (!label) {
            label = 'error'
          }
          // Opera incorrectly does not fill the validationMessage property.
          //message = node.validationMessage || 'Invalid value.';
          if (!self.errors.filter((error) => error.title == label).length) {
            self.errors.push({
              title: label,
              message: node.validationMessage || 'Invalid value.'
            })
          }
        });
        if (invalidFields.length) {
          this.$nextTick(() => {
            var alerts = document.querySelectorAll(
              "div.alert")
            $('div.modal-body').animate({
            //  scrollTop: $(alerts[0]).offset().top - $("#movementDatadrawerHeading").height()
              scrollTop: 0
            }, 500);
          })
        }
        return invalidFields.length == 0
      },
      cancel() {
        this.closeMessage()
        this.showAddOrEditModal = false
      },
      goToPreviousPage() {
        if (this.movementEntryFormPageIndex) {
          this.movementEntryFormPageIndex -= 1
        }
      },
      goToNextPage() {
        if (this.movementEntryFormPageIndex < 2) {
          this.movementEntryFormPageIndex += 1
        }
      },
      closeError() {
        this.errors.splice(0, this.errors.length)
      }
    },
    mounted() {
      const self = this
      this.$refs.movementEntryForm.closeMessage = this.closeMessage
      eventBus.$on(EDIT_MOVEMENT_EVENT, self.editMovement)
      eventBus.$on(CLOSE_MODAL_EVENT, () => {
        self.showExcludeFromBillWarning = false
        self.showAddOrEditModal = false
        self.closeMessage()
        self.closeError()
      })
    },
    beforeDestroy() {
      eventBus.$off(CLOSE_MODAL_EVENT)
      eventBus.$off(EDIT_MOVEMENT_EVENT)
    }
  };</script>
